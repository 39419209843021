import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Hillsboroughhero from "../components/hero/Hillsboroughhero";
import { FaSistrix } from "react-icons/fa";

function Hillsborough() {
  return (
    <Layout>
      <Hillsboroughhero />
      <Container>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <h3>HILLSBOROUGH COUNTY</h3>
            <p>
              Hillsborough county is a place rich in history and culture. Every
              year they celebrate Jose Gaspar’s pirate invasion with a big boat
              flotilla to the city and then the bead throwing parade from floats
              filled with pirates! The City of Ybor brings you back in time with
              it’s old cigar factories and Spanish restaurants like the
              “Columbia Restaurant” who have been serving South Tampa since
              1905! Or the classic destination restaurant “Berns Steak” which
              was opened in 1956 and is a true classic steak house and boasts an
              incredibly huge wine list. There are also many music venues, with
              a night life to rival Miami!
            </p>
            <p>
              For sports fans Hillsborough County has a fantastic football
              stadium the boasts the Super Bowl Champions the Tampa Bay
              Buccaneers and the Stanley Cup winning Tampa Bay Lightening!!
            </p>
            <p>
              From Riverwalk to Harborside to the Hillsborough River there is
              plenty of waterfront to enjoy what Florida is known for also.
            </p>
            <p>
              You can also enjoy the theme parks like Busch Gardens and
              Adventure Island.
            </p>
            <p>
              Hillsborough’s high schools rank among the best schools across the
              state and the entire nation. Some of the high performing schools
              include Plant High School and Newsome High School. Hillsborough
              County is also home to some of Florida’s well-regarded colleges
              and universities, including the University of South Florida,
              Hillsborough Community College, and the University of Tampa.
            </p>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <h5>General Faqs</h5>
            <ul>
              <li>
                <FaSistrix /> Population: 1,408,566 (2017 census)
              </li>
              <li>
                <FaSistrix /> 4th Most Populous County in Florida
              </li>
              <li>
                <FaSistrix /> Founded: January 25, 1834
              </li>
              <li>
                <FaSistrix /> County Seat: Tampa
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="mt-30">
        <Row className="mb-30">
          <h3>Service Locations for Hillsborough County</h3>
        </Row>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <ul>
              <li>
                <FaSistrix /> Tampa
              </li>
              <li>
                <FaSistrix /> Westchase
              </li>
              <li>
                <FaSistrix /> Carrollwood
              </li>
              <li>
                <FaSistrix /> Lutz
              </li>
              <li>
                <FaSistrix /> South Tampa
              </li>
              <li>
                <FaSistrix /> Harbor Island
              </li>
              <li>
                <FaSistrix /> Davis Island
              </li>
              <li>
                <FaSistrix /> Town N Country
              </li>
              <li>
                <FaSistrix /> New Tampa
              </li>
              <li>
                <FaSistrix /> Brandon
              </li>
              <li>
                <FaSistrix /> Seffner
              </li>
              <li>
                <FaSistrix /> Riverview
              </li>
              <li>
                <FaSistrix /> Lithia
              </li>
              <li>
                <FaSistrix /> Valrico
              </li>
              <li>
                <FaSistrix /> Apollo Beach
              </li>
              <li>
                <FaSistrix /> Balm
              </li>
              <li>
                <FaSistrix /> Dover
              </li>
              <li>
                <FaSistrix /> Durant
              </li>
              <li>
                <FaSistrix /> Gibsonton
              </li>
              <li>
                <FaSistrix /> Mango
              </li>
              <li>
                <FaSistrix /> Odessa
              </li>
              <li>
                <FaSistrix /> Plant City
              </li>
              <li>
                <FaSistrix /> Riverview
              </li>
              <li>
                <FaSistrix /> Ruskin
              </li>
              <li>
                <FaSistrix /> Sun City
              </li>
              <li>
                <FaSistrix /> Sydney
              </li>
              <li>
                <FaSistrix /> Wimauma
              </li>
              <li>
                <FaSistrix /> Thonotosassa
              </li>
              <li>
                <FaSistrix /> Temple Terrace
              </li>
              <li>
                <FaSistrix /> Seminole Heights
              </li>
              <li>
                <FaSistrix /> Riverview Heights
              </li>
              <li>
                <FaSistrix /> Palma Ceia
              </li>
              <li>
                <FaSistrix /> Ybor City
              </li>
              <li>
                <FaSistrix /> Hyde Park/Soho
              </li>
            </ul>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <a
              href="/static/337341743529f0b723f504c8632cbe79/3f4aa/hillsboroughmap.jpg"
              target="blank"
            >
              <StaticImage src="../images/hillsboroughmap.jpg" />
            </a>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Hillsborough;
